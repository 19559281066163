import RouteConfigInterface from "./RouteConfigInterface";
import Welcome from '../pages/Welcome/Welcome';
import Splash from '../pages/Splash/Splash';
import Permissions from '../pages/Permissions/Permissions';
import Notice from '../pages/Notice/Notice';
import Success from '../pages/Success/Success';
import PhoneValidation from '../pages/PhoneValidation/PhoneValidation';
import Checklist from '../pages/Checklist/Checklist';
import IdentificationDocument from '../pages/IdentificationDocument/IdentificationDocument';
import IdentificationRGWelcome from '../pages/IdentificationRGWelcome/IdentificationRGWelcome';
import IdentificationRgFront from '../pages/IdentificationRgFront/IdentificationRgFront';
import IdentificationRgVerse from '../pages/IdentificationRgVerse/IdentificationRgVerse';
import SelfieValidation from '../pages/SelfieValidation/SelfieValidation';
import EngineShipping from '../pages/EngineShipping/EngineShipping';
import LicensePlate from '../pages/LicensePlate/LicensePlate';
import VehicleDocument from '../pages/VehicleDocument/VehicleDocument';
import EnergyBill from '../pages/EnergyBill/EnergyBill';
import ContractRead from '../pages/ContractRead/ContractRead';
import ContractReview from '../pages/ContractReview/ContractReview';
import UpdateBankInfo from '../pages/UpdateBankInfo/UpdateBankInfo';

const MainRouteItems: RouteConfigInterface[] = [
    {path: '/', element: <Welcome/>},
    {path: '/f/:guid', element: <Splash/>},
    {path: '/permissions', element: <Permissions/>},
    {path: '/notice/:type', element: <Notice/>},
    {path: '/success', element: <Success/>},
    {path: '/phone-validation', element: <PhoneValidation/>},
    {path: '/checklist', element: <Checklist/>},
    {path: '/identification-document/:item', element: <IdentificationDocument/>},
    {path: '/identification-document/:item/:tipo/:etapa', element: <IdentificationDocument/>},
    {path: '/identification-cnh/:item/:etapa', element: <IdentificationDocument/>},
    {path: '/identification-rg-welcome/:item/:etapa', element: <IdentificationRGWelcome/>},
    {path: '/identification-rg-front/:item/:etapa', element: <IdentificationRgFront/>},
    {path: '/identification-rg-verse/:item/:etapa', element: <IdentificationRgVerse/>},
    {path: '/selfie-validation/:item', element: <SelfieValidation/>},
    {path: '/engine-shipping/:item', element: <EngineShipping/>},
    {path: '/license-plate/:item', element: <LicensePlate/>},
    {path: '/vehicle-document/:item', element: <VehicleDocument/>},
    {path: '/energy-bill/:item', element: <EnergyBill/>},
    {path: '/video-confirm/:item', element: <ContractRead/>},
    {path: '/contract-review', element: <ContractReview/>},
    {path: '/update-bank', element: <UpdateBankInfo/>},
    {path: '/*', element: <h1>Not found!</h1>},
];

export default MainRouteItems;
