import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Route, Routes} from 'react-router-dom';
import {CameraProvider} from '../providers/CameraProvider';
import {FileProvider} from '../providers/FileProvider';
import {StyleProvider} from "../providers/StyleProvider";
import RouteConfigInterface from "./RouteConfigInterface";
import MainRouteItems from "./MainRouteItems";

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
        subtitle1: {
            fontSize: 16,
            lineHeight: '22px',
        },
        body1: {
            fontWeight: 500,
        },
        button: {
            fontStyle: 'italic',
        },
    },
});

const AllRoutes = (): JSX.Element => (
    <Routes>
        {MainRouteItems.map((route: RouteConfigInterface, index: number) => (
            <Route key={index} path={route.path} element={route.element}/>
        ))}
    </Routes>
);

const RootRoute = () => {
    return (
        <ThemeProvider theme={theme}>
            <CameraProvider>
                <FileProvider>
                    <StyleProvider>
                        <AllRoutes/>
                    </StyleProvider>
                </FileProvider>
            </CameraProvider>
        </ThemeProvider>
    );
}

export default RootRoute;